import * as React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"


import { Container, Row, Col, Carousel } from "react-bootstrap"



const IndexPage = () => (
  <Layout>
    <Seo title="Sunset Cocktail Tour" />

    <Carousel interval={5000}>
      <Carousel.Item>
        <StaticImage
          src="../images/sunset-cocktail-hour/01-sunset-cocktail-hour.jpg"
          quality={95}
          aspectRatio={2}
          formats={["auto", "webp", "avif"]}
          alt="Sunset Cocktail Tour"
        />
      </Carousel.Item>
      <Carousel.Item>
        <StaticImage
          src="../images/sunset-cocktail-hour/02-sunset-cocktail-hour.jpg"
          quality={95}
          aspectRatio={2}
          formats={["auto", "webp", "avif"]}
          alt="Sunset Cocktail Tour"
        />
      </Carousel.Item>
      <Carousel.Item>
        <StaticImage
          src="../images/sunset-cocktail-hour/03-sunset-cocktail-hour.jpg"
          quality={95}
          aspectRatio={2}
          formats={["auto", "webp", "avif"]}
          alt="Sunset Cocktail Tour"
        />
      </Carousel.Item>
      <Carousel.Item>
        <StaticImage
          src="../images/sunset-cocktail-hour/04-sunset-cocktail-hour.jpg"
          quality={95}
          aspectRatio={2}
          formats={["auto", "webp", "avif"]}
          alt="Sunset Cocktail Tour"
        />
      </Carousel.Item>
      <Carousel.Item>
        <StaticImage
          src="../images/sunset-cocktail-hour/05-sunset-cocktail-hour.jpg"
          quality={95}
          aspectRatio={2}
          formats={["auto", "webp", "avif"]}
          alt="Sunset Cocktail Tour"
        />
      </Carousel.Item>
      <Carousel.Item>
        <StaticImage
          src="../images/sunset-cocktail-hour/06-sunset-cocktail-hour.jpg"
          quality={95}
          aspectRatio={2}
          formats={["auto", "webp", "avif"]}
          alt="Sunset Cocktail Tour"
        />
      </Carousel.Item>
      
    </Carousel>
    <div className="hawaiianBG d-flex justify-content-center py-3">
      <h1>Sunset Cocktail Tour</h1>
    </div>
    <Container className="p-4">
    
    
    <Row className="g-5">
      <Col lg={8}>
        <h2>Unwind with our Sunset Cocktail Tour</h2>
        <p>
        <StaticImage
          src="../images/happy-hour-cocktail.jpg"
          quality={95}
          aspectRatio={2}
          formats={["auto", "webp", "avif"]}
          alt="Tuk Tuks"
          
        />
        </p>
        <p>
          Buzz around Rarotonga’s beach-fringed landscapes as you seek out the best drinks and happy hour specials this small island has to offer.  Get around on an environmentally friendly electric tuk tuk, and get tips on the coolest places to go and drinks to order, from your in-the-know local guide.
        </p>
        <p>
          Hop in the back seat of an electric tuk tuk just outside of your hotel, and make your way to a beach-side bar as the sun begins to dip towards the horizon.  Consider grabbing an ice-cold Big Kahuna, sip a Seabreeze, or find out what goes into a Sex on the Beach.
        </p>
        <p>
          When you finish your drink, hop back in the tuk tuk and cruise to your next location to grab another tropical cocktail served up in a perfect setting for watching the sunset.  Get tips on what to order from your guide, and find out about other great places to grab a drink before night sets in and you head back to your hotel.
        </p>
        <p>
          <em>
          Minimum 4 people per booking is required. Operating weeknight evenings by appointment. 4pm pick up, 7.30pm drop off. Price is $65 per person (drinks excluded).
          </em>
        </p>

      </Col>
      <Col lg={4}>
        <p className="lead">Recent Press</p>
        <p>
          <a href="http://www.nzherald.co.nz/travel/news/article.cfm?c_id=7&objectid=11731227">
            <StaticImage
              src="../images/nz-herald.png"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="New Zealand Herald Logo"
            />
          </a>
        </p>
        <p>
          <a href="https://www.usatoday.com/story/travel/destinations/2017/04/01/cook-islands/99882942/">
            <StaticImage
              src="../images/large_USAToday_Logo_Square.jpg"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="New Zealand Herald Logo"
            />
          </a>
        </p>
        <p>
          <a href="https://kiwiliving.nz/holidays/mike-in-rarotonga">
            <StaticImage
              src="../images/kiwi-living.jpg"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="New Zealand Herald Logo"
            />
          </a>
        </p>
        
      </Col>
    </Row>
    
    </Container>
      
  </Layout>
)

export default IndexPage
